import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-resource-list-item',
	imports: [CommonModule],
	templateUrl: 'resource-list-item.component.html',
	styleUrls: ['resource-list-item.component.scss']
})
export class ResourceListItemComponent {
	@HostBinding('attr.disabled')
	@Input()
	disabled = false;
}
