import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-resource-list-header',
	templateUrl: 'resource-list-header.component.html',
	styleUrls: ['resource-list-header.component.scss']
})
export class ResourceListHeaderComponent {
	@HostBinding('attr.size')
	@Input()
	size: 'small' | 'medium' = 'medium';
}
