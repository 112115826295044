<ng-container *ngIf="!loading; else loader">
	<table>
		<tbody>
			<ng-content></ng-content>
		</tbody>
	</table>
</ng-container>

<ng-template #loader>
	<div class="loader">
		<app-loading-spinner></app-loading-spinner>
	</div>
</ng-template>
