import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';
import { ResourceListEmptyStateComponent } from '../resource-list-empty-state/resource-list-empty-state.component';

@Component({
	standalone: true,
	selector: 'app-resource-list-table',
	imports: [
		CommonModule,
		LoadingSpinnerComponent,
		ResourceListEmptyStateComponent
	],
	templateUrl: 'resource-list-table.component.html',
	styleUrls: ['resource-list-table.component.scss']
})
export class ResourceListTableComponent {
	@Input() loading = false;
}
