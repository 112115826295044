import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-resource-list-footer',
	templateUrl: 'resource-list-footer.component.html',
	styleUrls: ['resource-list-footer.component.scss']
})
export class ResourceListFooterComponent {
	/**
	 * What size to render the footer as. Different sizes are needed
	 * for different contexts
	 */
	@HostBinding('attr.size')
	@Input()
	size: 'small' | 'medium' = 'medium';
}
